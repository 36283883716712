import React from "react"
import { Button } from "../Button"
import { stringToFlags } from "../../helpers"
import aaiHero from "../../images/img-homehero-1.webp"
import tw from "twin.macro"
import { ModalContext } from "../Layout"
import HeroIcon from "../../images/HeroIcon"
import numberSvg from "../../images/96.svg"

const Hero = ({ data }) => {
  return (
    <ModalContext.Consumer>
      {({ setActiveModal }) => (
        <section
          css={[
            tw`bg-cover object-center relative`,
            `background-image: url(${aaiHero});`,
          ]}
        >
          <div
            css={[
              tw`hidden lg:flex absolute right-[13%] top-[10%] flex-col max-w-[8rem]`,
            ]}
          >
            <div css={[tw`text-center text-white mb-2 font-extrabold italic`]}>
              SUPPORT OR BECOME A
            </div>
            <HeroIcon />
          </div>
          <div tw="py-8 w-full min-h-screen flex items-center justify-center z-40">
            <div tw="grid grid-cols-12">
              <div tw="px-3 gap-2 col-span-12 lg:col-span-8">
                <div tw="pb-12 flex justify-center">
                  <iframe
                    style={{ aspectRatio: '16 / 9' }}
                    tw="max-w-full h-full"
                    width="720"
                    height="405"
                    src="https://www.youtube.com/embed/0GhoSuQxx-o?rel=0"
                    title="YouTube video player"
                    frameborder="0"
                    modestbranding="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                  </iframe>
                </div>
                <h2
                  dangerouslySetInnerHTML={{ __html: data.headline }}
                  className="stroke-text white"
                  tw="z-20 text-center mb-7"
                />
              </div>
              <div tw="sm:px-2 md:px-4 col-span-12 lg:col-span-8 row-start-2 flex flex-col sm:flex-row justify-center sm:gap-2 md:mb-12 lg:mb-40 mx-4">
                {data.ctas.map((cta, index) => (
                  <div key={index} tw="grid text-center z-index[3] flex-1">
                    <p
                      css={[
                        tw`-mb-2 mt-3 uppercase text-xs px-2 sm:mt-0 sm:w-auto text-white`,
                        "@-moz-document url-prefix(){ margin-bottom: 0.5rem;}}",
                      ]}
                    >
                      {cta.crown}
                    </p>
                    <br />
                    <Button
                      onClick={() => setActiveModal(cta.url)}
                      {...stringToFlags(cta.button)}
                      css={[
                        tw`self-end h-14 text-sm py-2`,
                        "@media(min-width: 1645px){height: auto;}",
                      ]}
                    >
                      {cta.label}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </ModalContext.Consumer>
  )
}

export default Hero
