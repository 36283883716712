import React from 'react';

interface Props {

}

const HeroIcon: React.FC<Props> = (props) => {
  return (
      <svg width="100%" height="100%" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
        <text x="22%" y="60%" fill='#FCFCFC' fontSize={15} fontFamily='Montserrat' fontWeight="800">TTC</text>
      <g fillRule="nonzero" fill="none">
          <path d="M28 56C12.572 56 0 43.428 0 28S12.572 0 28 0s28 12.572 28 28-12.572 28-28 28zm0-54.414C13.445 1.586 1.586 13.445 1.586 28c0 14.555 11.859 26.414 26.414 26.414 14.555 0 26.414-11.859 26.414-26.414C54.414 13.445 42.555 1.586 28 1.586z" fill="#B30838"/>
          <path d="M28 2C13.645 2 2 13.645 2 28s11.645 26 26 26c14.354 0 26-11.645 26-26 .04-14.355-11.646-26-26-26zm0 44.964C17.527 46.964 9.036 38.473 9.036 28 9.036 17.527 17.527 9.036 28 9.036c10.473 0 18.964 8.491 18.964 18.964 0 10.473-8.491 18.964-18.964 18.964z" fill="#B30838"/>
          <g fill="#231F20">
          </g>
          <g fill="#FCFCFC">
              <path d="m10.025 14.667.592.416.986-1.541.552.416-.986 1.542 1.143.833-.433.667L9 14.958l1.538-2.458.552.417zM13.89 9.833l.473.584 1.34-1.209.435.542-1.341 1.208.473.584 1.538-1.417.434.542-2.13 1.916-2.247-2.791 2.129-1.917.434.542zM21.106 7.625l-1.262.625.08.75-.75.333-.197-3.958.71-.333 2.681 2.75-.75.375-.512-.542zm-.512-.583-.947-1 .118 1.416.829-.416zM26.47 3.833l-1.105.209.473 2.916-.749.125-.473-2.916-1.065.208-.118-.708 2.918-.542zM32.581 3.292l-.276 2.958-.749.625-1.498-.167-.631-.75L29.703 3l.749.083-.237 2.625.237.334.789.083.355-.292.236-2.625zM36.88 8.542 36.722 7l-.71-.292-.473 1.25-.71-.291L36.13 4.25l2.17.917.315.791-.395 1-.71.334.198 1.583-.828-.333zM36.288 6l1.104.5.237-.125.197-.458-.118-.25-1.104-.459-.316.792zM42.124 8.375l-.433.583 1.38 1.167-.434.583-1.38-1.166-.434.583 1.617 1.333-.434.584-2.208-1.834 2.13-2.916 2.207 1.833-.433.583zM47.803 14.792l-1.696 1.291-.946-.166-1.341-1.959 2.84-2.166L48 13.75l-.197 1.042zm-.71-.459.079-.416-.67-1-1.696 1.291.67 1 .395.084 1.222-.959z"/>
          </g>
          <g fill="#FCFCFC">
              <path d="m13.185 45.58-.993-.979-.636.314-.556-.548 3.456-1.527.556.548-1.51 3.406-.595-.588.278-.626zm.318-.626.556-1.253-1.232.587.676.666zM19.541 47.38l-.953-.547-1.39 2.388-.676-.392 1.39-2.388-.953-.548.358-.587 2.582 1.488zM24.984 48.36l-.834 3.366-.755-.196.358-1.37-1.39-.352-.358 1.37-.755-.196.834-3.366.755.195-.318 1.331 1.39.353.318-1.331zM30.268 51.256l.04.666-2.622.078-.12-3.445.755-.039.08 2.78zM33.486 48.516l.198.665 1.709-.548.198.666-1.708.548.199.665 1.946-.587.199.666-2.701.822-1.073-3.288 2.701-.823.199.666zM40.12 45.267l-.913.587 1.549 2.31-.636.43-1.55-2.31-.913.588-.397-.587 2.503-1.605zM42.544 42.996l.516.47 1.192-1.33.516.43-1.191 1.33.556.47 1.35-1.526.517.43-1.867 2.075-2.662-2.27L43.338 41l.517.43z"/>
          </g>
      </g>
      </svg>

  )
}

export default HeroIcon