import React from "react"
import Button from "../../components/Button"
import tw from "twin.macro"
import { ModalContext } from "../Layout"

const CTA = ({ data }) => {
  return (
    <ModalContext.Consumer>
      {({ setActiveModal }) => (
        <section tw="">
          <div tw="grid grid-cols-12 sm:-mt-80 mb-0 sm:mb-20">
            <div css={[tw`gap-2 col-span-12 lg:col-span-9 p-6 w-full text-center bg-primary relative`, `@media(min-width: 648px){clip-path: polygon(0 0, 100% 0, 93% 100%, 0 100%, 0 0)}` ]}>
              <h2
                dangerouslySetInnerHTML={{ __html: data.headline }}
                tw="text-center mb-4 text-white"
              />
              <div tw="block sm:flex sm:flex-1 sm:justify-center">
              <div css={[tw`flex gap-12 items-center text-white`]}>
                <Button onClick={() => setActiveModal("sponsor")} white tw="ml-0 w-full sm:ml-4 sm:w-auto">
                  Become A Sponsor
                </Button>
                <p css={[tw`hidden sm:block text-xs tracking-[2.3px] w-40`]}>CONTACT US TO LEARN MORE</p>
              </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </ModalContext.Consumer>
  )
}

export default CTA
