import { graphql } from "gatsby"
import * as React from "react"
import tw from "twin.macro"
import Seo from "../components/Seo"
import { Hero, About, FAQs, CTA, Commitment } from "../components/Home"

import Layout from "../components/Layout"

const IndexPage = ({ data }) => {
  const { hero, about, faqs, cta } = data.homeYaml
  return (
    <Layout>
      <Seo />
      <Hero data={hero} />
      <Commitment />
      <About data={about} />
      <FAQs data={faqs} />
      <CTA data={cta} />
      {/*<Button primary>Primary</Button>
      <Button white>White</Button>
      <Button white outline>
        White outline
      </Button>
      <Button primary outline>
        Primary outline
      </Button>*/}
    </Layout>
  )
}

export const query = graphql`
  {
    homeYaml {
      about {
        headline
        subheadline
        paragraph
        for_sponsors {
          headline
          paragraph
          bullet_points
          cta {
            label
            url
            button
          }
        }
        for_supporters {
          headline
          paragraph
          bullet_points
          cta {
            label
            url
            button
          }
        }
        for_athletes {
          headline
          paragraph
          bullet_points
          cta {
            label
            url
            button
          }
        }
      }
      hero {
        headline
        ctas {
          crown
          label
          url
          button
        }
      }
      faqs {
        headline
        subheadline
        faqs {
          question
          answer
        }
      }
      cta {
        headline
        ctas {
          label
          url
          button
        }
      }
    }
  }
`

export default IndexPage
