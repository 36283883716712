import React from 'react';
import tw from 'twin.macro';

const Commitment = () => {
    return (
        <section css={[tw`bg-[#231f20] text-center flex flex-col justify-center p-8 text-white md:min-h-[20rem] pt-8 md:pt-4`]}>
            <h3>OUR COMMITMENT</h3>
            <div css={[tw`max-w-5xl mx-auto mt-4 sm:mt-8 sm:grid sm:grid-cols-2`]}>
                <div css={tw`px-2 sm:border-r sm:border-gray-600 sm:px-10`}>
                    <p css={[tw`text-sm text-left sm:text-center`]}>
                        The Tuscaloosa Connection is owned by The Alabama Athletes Trust.
                    </p>
                </div>
                <div css={tw`px-2 flex items-center mt-3 sm:px-10 sm:block sm:mt-0`}>
                    <p css={[tw`text-[2rem] leading-[4rem] mr-3 font-semibold sm:text-[2.75rem] sm:mr-0`]}>100%</p>
                    <p css={[tw`text-sm text-left sm:text-center ml-4 sm:ml-0`]}>
                        of all revenue goes to benefit the athletes.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Commitment