import React from "react"
import { stringToFlags } from "../../helpers"
import { Button } from "../Button"
import aboutImage1 from "../../images/about-1.webp"
import aboutImage2 from "../../images/about-4.webp"
import aboutImage3 from "../../images/about-3.webp"
import aboutImage4 from "../../images/about-2.webp"
import tw from "twin.macro"
import { ModalContext } from "../Layout"
import aboutBg from "../../images/about-bg.svg"

const About = ({ data }) => {
  return (
    <ModalContext.Consumer>
      {({ setActiveModal }) => (
        <section
          tw="bg-gray-mid relative"
          id="benefits"
          css={[
            tw`bg-cover bg-center`,
            `background-image: url(${aboutBg}); overflow-x: hidden; overflow-x: clip;`,
          ]}
        >
          <div tw="grid grid-cols-3 gap-2 relative">
            <div>
              <img
                alt=""
                src={aboutImage1}
                loading="lazy"
                tw="absolute hidden opacity-30 h-4/5 bottom-0 left-0 z-index[1] md:opacity-100 md:z-index[2] md:h-4/5 lg:h-3/6 xl:h-3/5 2xl:h-4/5 lg:block"
              />
            </div>
            <div tw="col-span-12 sm:col-span-12 py-8 sm:py-20 px-8 sm:px-0 sm:mx-10 md:col-span-12 lg:col-span-1">
              <h2
                dangerouslySetInnerHTML={{ __html: data.headline }}
                className="stroke-text white"
                tw="text-center"
              />
              <p tw="text-center uppercase text-primaryDark font-weight[800]">
                {data.subheadline}
              </p>
              <p tw="text-center">{data.paragraph}</p>
            </div>
            <div css={[tw`z-30`]}>
              <img
                alt=""
                loading="lazy"
                src={aboutImage2}
                tw="absolute hidden opacity-30 h-4/5 -top-10 right-5 z-index[1] md:opacity-100 md:z-index[2] md:h-4/5 lg:h-3/6 xl:h-3/5 2xl:h-4/5 lg:block"
              />
              <img
                alt=""
                loading="lazy"
                src={aboutImage3}
                tw="absolute hidden opacity-30 h-4/5 top-60 right-14 z-index[1] md:opacity-100 md:z-index[2] md:h-4/5 lg:h-3/6 xl:h-3/5 2xl:h-4/5 lg:block"
              />
              <div tw="overflow-x-hidden w-full">
                <img
                  alt=""
                  loading="lazy"
                  src={aboutImage4}
                  tw="absolute hidden opacity-30 h-4/5 top-96 -right-40 z-index[1] md:opacity-100 md:z-index[2] md:h-4/5 lg:h-3/6 xl:h-3/5 2xl:h-4/5 lg:block"
                />
              </div>
            </div>
          </div>
          <div tw="grid grid-cols-1 lg:grid-cols-12 lg:pb-20">
            <article
              tw="bg-primary shadow-lg p-8 sm:p-12 text-white m-0 sm:m-10 md:col-start-2 md:col-span-5 lg:col-start-1 lg:col-span-4 lg:px-8 lg:mx-6 lg:mt-10 lg:mb-40 xl:ml-20 xl:mr-8 xl:mt-20 xl:mb-40 2xl:sm:p-12"
              id="for-sponsors"
            >
              <h3>{data.for_sponsors.headline}</h3>
              <p tw="mt-3">{data.for_sponsors.paragraph}</p>
              <ul tw="mb-6 mt-6">
                {data.for_sponsors.bullet_points.map((point, index) => (
                  <li key={index} tw="font-bold mb-3">
                    {point}
                  </li>
                ))}
              </ul>
              <Button
                onClick={() => setActiveModal(data.for_sponsors.cta.url)}
                {...stringToFlags(data.for_sponsors.cta.button)}
              >
                {data.for_sponsors.cta.label}
              </Button>
            </article>
            <article
              tw="bg-gray-mid col-span-12 z-index[3] shadow-lg p-6 sm:p-12 m-0 text-white sm:m-10 lg:col-start-5 lg:px-8 lg:mx-8 lg:mt-28 lg:mb-[5.5rem] lg:col-span-4 xl:mx-12 xl:mt-40 2xl:mt-[12.5rem] 2xl:sm:p-12"
              id="for-supporters"
            >
              <h3>{data.for_supporters.headline}</h3>
              <p tw="mt-3">{data.for_supporters.paragraph}</p>
              <ul tw="mb-6 mt-6">
                {data.for_supporters.bullet_points.map((point, index) => (
                  <li key={index} tw="font-bold mb-3">
                    {point}
                  </li>
                ))}
              </ul>
              <Button
                onClick={() => setActiveModal(data.for_supporters.cta.url)}
                {...stringToFlags(data.for_supporters.cta.button)}
              >
                {data.for_supporters.cta.label}
              </Button>
            </article>
            <article
              css={[
                tw`bg-primary col-span-12 z-index[3] shadow-lg p-6 m-0 text-white`,
                tw`sm:p-12 sm:m-10 md:col-start-2 md:col-span-5 lg:col-start-9 lg:col-span-4 lg:px-8 lg:mx-8 lg:mt-[11.5rem] lg:mb-4 xl:ml-8 xl:mr-20 xl:mt-60 2xl:mb-0 2xl:mt-80 2xl:sm:p-12`]}
              id="for-athletes"
            >
              <h3>{data.for_athletes.headline}</h3>
              <p tw="mt-3">{data.for_athletes.paragraph}</p>
              <ul tw="mb-6 mt-6">
                {data.for_athletes.bullet_points.map((point, index) => (
                  <li key={index} tw="font-bold mb-3">
                    {point}
                  </li>
                ))}
              </ul>
              <Button
                onClick={() => setActiveModal(data.for_athletes.cta.url)}
                {...stringToFlags(data.for_athletes.cta.button)}
              >
                {data.for_athletes.cta.label}
              </Button>
            </article>
          </div>
        </section>
      )}
    </ModalContext.Consumer>
  )
}

export default About
