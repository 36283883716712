import React from "react"
import Accordion from "../Accordion"
import footerImage from "../../images/img-homefooter.webp"
import tw from "twin.macro"
import numberSvg from "../../images/81.svg"

const FAQs = ({ data }) => {
  return (
    <section
      id="faqs"
      css={[
        tw`bg-no-repeat bg-auto bg-left-top md:bg-left-bottom sm:bg-cover min-h-[500px] relative sm:min-h-[800px] lg:min-height[1100px] sm:pb-80`,
        `background-image: url(${footerImage});`,
      ]}
    >
      <div css={[tw`hidden lg:block absolute w-[6rem] right-20 top-10`]}>
      </div>
      <div css={[tw`flex flex-col text-white max-w-[250px] items-end absolute bottom-[22rem] right-20 hidden lg:flex`]}>
        <img src={numberSvg} alt="" tw="w-48" />
        <p css={[tw`text-xl text-center`]}>STUDENT ATHLETES ON FALL 2022 HONOR ROLL</p>
      </div>
      <div tw="grid grid-cols-12">
        <div tw="gap-0 col-span-12 p-10 mt-0 md:mt-0 sm:gap-0 sm:mb-16 lg:gap-2 lg:col-span-6 bg-white opacity-80 lg:p-20 lg:mb-16 lg:m-0 lg:mt-20">
          {/*  {JSON.stringify(data)}*/}
          <h2
            dangerouslySetInnerHTML={{ __html: data.headline }}
            className="stroke-text black"
            tw="text-center"
          />
          <p tw="text-center font-bold text-red-800 m-6 md:m-2">
            {data.subheadline}
          </p>
          <div>
            {data.faqs.map(({ question, answer }, index) => (
              <Accordion.Item key={index}>
                <Accordion.Title>{question}</Accordion.Title>
                <Accordion.Body>{answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQs
