import React, { useState, useRef, useEffect } from "react"
import tw from "twin.macro"
import carat from "../../images/carat.svg"

export const Item = ({ children }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div tw="border-b border-b-gray-400 pt-4 pb-2">
      {React.Children.map(children, child =>
        React.cloneElement(child, { visible: visible, setVisible: setVisible })
      )}
    </div>
  )
}
export const Title = ({ children, visible, setVisible }) => {
  return (
    <div css={[tw`flex items-center`]}>
      <button
        onClick={() => setVisible(prev => !prev)}
        css={[tw`uppercase cursor-pointer font-semibold mb-2 w-full text-left`]}
      >
        {children}
      </button>
      <img src={carat} css={[tw`h-4 mr-2 -mt-2 transition-transform rotate-180`, visible && tw`rotate-0`]} alt="" />
    </div>
  )
}
export const Body = ({ children, visible }) => {
  const [bodyHeight, setBodyHeight] = useState(0)
  const bodyRef = useRef(null)
  let initialHeight = bodyRef?.current?.clientHeight

  useEffect(() => {
    if (visible) {
      setBodyHeight(initialHeight)
    } else {
      setBodyHeight(0)
    }
  }, [visible])

  return (
    <div
      style={{ height: `${bodyHeight}px` }}
      css={[
        tw`relative overflow-hidden transition-all duration-300 ease-in-out`,
      ]}
    >
      <div ref={bodyRef} css={[tw`absolute`]}>
        {children}
      </div>
    </div>
  )
}

export default { Item, Title, Body }
